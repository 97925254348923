.createPageBtnOptions {
  display: flex;
  flex-direction: row;
}
.confirmation-message {
  position: fixed !important;
  background: #323232 !important;
  border-radius: 4px;
  color: white !important;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
}
.ui.input {
  width: 100%;
}

.btnSpacer {
  width: 10px;
}

.modalContainer {
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.pickupInstructionsHeader {
  margin-top: 0px;
}

.datepicker {
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  border: 2px solid #a3a8bf;
  font-family: "AvenirRegular";
  font-size: 16px;
  color: #000;
}

.communityRequestsContainer {
  padding: 24px;
  padding-left: 100px;
  padding-right: 100px;
}

.communityListDescription {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.communityRequestDescriptionHeader {
  color: #00ba34;
  font-size: 26px;
  font-family: "AvenirBold";
}

.howDoesItWorkLink {
  color: #646464;
  font-size: 16px;
  font-family: "AvenirRegular";
  text-decoration: underline;
}

.howDoesItWorkLink:hover {
  color: #000;
  text-decoration: underline;
}

.requestDescription {
  color: #646464;
  font-size: 18px;
  font-family: "AvenirRegular";
}

.header {
  font-size: 36px;
  font-family: "AvenirBold";
}

.CreateNewCommunityEventBtnContainer {
  display: flex;
  justify-content: center;
}
